/** @jsx jsx */
import { jsx, Grid, Box } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FaTwitter, FaFacebook, FaEnvelope } from "react-icons/fa"

const Footer = ({ navPosition }) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "page-contact" } }) {
        id
        frontmatter {
          link_spotlight
          link_imdb
          link_facebook
          link_twitter
          link_mandy
          link_vimeo
        }
      }
    }
  `)
  const { link_facebook, link_twitter } = data.markdownRemark.frontmatter
  return (
    <footer
      sx={{
        bg: "text",
        color: "white",
        textAlign: ["center", "left"],
        p: 3,
        "& .footer-right": {
          gridTemplateColumns: ["none", "2fr 1fr", "3fr 1fr"],
        },
        "& .footer-bottom": {
          gridTemplateColumns: "1fr",
          justifyItems: "center",
        },
        "& a.footer-social": {
          ml: 2,
          fontSize: 3,
          pt: 2,
        },
      }}
    >
      <Grid
        gap={2}
        columns={["none", "1fr 2fr", "1fr 3fr"]}
        className={`footer-${navPosition}`}
      >
        {navPosition === "left" && <div />}

        <div>
          <p>
            Portia Booroff is a UK Actor represented by Nancy Hudson Associates
            -{" "}
            <a href="mailto:agents@nancyhudsonassociates.com">
              agents@nancyhudsonassociates.com
            </a>
          </p>
          <Box>
            <a href={link_twitter} className="footer-social">
              <FaTwitter />
            </a>
            <a href={link_facebook} className="footer-social">
              <FaFacebook />
            </a>
            <Link to="/contact" className="footer-social">
              <FaEnvelope />
            </Link>
          </Box>
          <p>
            website by{" "}
            <a href="https://www.exposuredesign.co.uk/">Exposure Design</a>
          </p>
        </div>

        {navPosition === "right" && <div />}
      </Grid>
    </footer>
  )
}

export default Footer
