/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import React from "react"
import { slide as Menu } from "react-burger-menu"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "30px",
    height: "25px",
    right: "22px",
    top: "22px",
  },
  bmBurgerBars: {
    background: "#fff",
  },
  bmBurgerBarsHover: {
    background: "#fff",
    opacity: 1,
  },
  bmCrossButton: {
    height: "2em",
    width: "2em",
  },
  bmCross: {
    background: "#fff",
    display: "none", //turned off
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    // background: "#ED4949", //primary in theme
    //add this property manually to sx prop down below with primary for color mode
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },

  //added by me to overright annoying effect where it dims on select
}

const NavMenuMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      site: markdownRemark(
        frontmatter: { templateKey: { eq: "site-options" } }
      ) {
        id
        frontmatter {
          name
          headshot {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  //colormode =>https://theme-ui.com/color-modes/
  const [colorMode, setColorMode] = useColorMode()

  return (
    <div
      sx={{
        display: ["block", "none"],
        position: "fixed",
        zIndex: 10,
        bg: "primary",
        color: "white",

        width: "100%",
        "& .bm-menu": {
          bg: "primary",
        },
        "& .bm-burger-button": {
          position: "fixed",
          width: "30px",
          height: "25px",
          right: "15px !important",
          top: "23px !important",
          "&::after": {
            content: "'menu'",
            position: "absolute",
            // bottom: "-23px",
            // right: "-4px",
            bottom: "2px",
            right: "37px",
          },
        },
      }}
    >
      <h1
        sx={{
          fontSize: [3, 6],
          lineHeight: 1,
          marginLeft: 3,
          marginTop: 3,
          marginBottom: 3,
          pl: 2,
          display: "inline-block",
          position: "relative",
          maxWidth: ["100px", "200px"],
          "::before": {
            content: '""',
            display: "block",
            position: "absolute",
            width: ["4px", "8px"],
            height: ["60%", "75%"],
            left: "-2px",
            top: ["20%", "12.5%"], //half of what is left from height
            backgroundColor: "white",
          },
        }}
      >
        {data.site.frontmatter.name}
      </h1>
      {/* <small>{window.location.pathname}</small> */}
      <Menu
        right="true"
        styles={styles}
        width={"50vw"}
        sx={{
          "& .bm-item-list": {
            position: "relative",
          },
        }}
      >
        <ul
          sx={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            marginRight: 0,
            float: "right",
            textAlign: "right",
            "& li": {
              marginBottom: "0.4em",
            },
            "& a": {
              color: "white",
              textDecoration: "none",
              fontSize: 3,
              "&:hover, &.active": {
                textDecoration: "underline",
              },
            },
          }}
        >
          {/* <li>
            <button
              onClick={e => {
                setColorMode(colorMode === "default" ? "blue" : "default")
              }}
            >
              Toggle {colorMode === "default" ? "blue" : "red"}
            </button>
          </li> */}
          <li>
            <Link to="/" activeClassName="active">
              Home
            </Link>
          </li>
          <li>
            <Link to="/news" activeClassName="active" partiallyActive={true}>
              Latest News
            </Link>
          </li>
          <li>
            <Link to="/cv" activeClassName="active" partiallyActive={true}>
              CV
            </Link>
          </li>
          <li>
            <Link
              to="/portfolio"
              activeClassName="active"
              partiallyActive={true}
            >
              Gallery
            </Link>
          </li>
          <li>
            <Link
              to="/showreels"
              activeClassName="active"
              partiallyActive={true}
            >
              Showreels
            </Link>
          </li>
          <li>
            <Link
              to="/testimonials"
              activeClassName="active"
              partiallyActive={true}
            >
              Testimonials
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="active" partiallyActive={true}>
              Contact
            </Link>
          </li>
        </ul>
        <Img
          fluid={data.site.frontmatter.headshot.childImageSharp.fluid}
          alt="Portia Booroff Headshot"
          sx={{
            width: "100%",
            border: "1px solid white",
            position: "absolute !important",

            display: "block",
            bottom: "15%",
            left: "1px",
          }}
        />
      </Menu>
    </div>
  )
}

export default NavMenuMobile
