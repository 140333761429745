/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const NavMenuDesktop = ({ navPosition }) => {
  const data = useStaticQuery(graphql`
    query {
      site: markdownRemark(
        frontmatter: { templateKey: { eq: "site-options" } }
      ) {
        id
        frontmatter {
          name
          headshot {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  //colormode =>https://theme-ui.com/color-modes/
  const [colorMode, setColorMode] = useColorMode()
  return (
    <section
      className={`navbar-${navPosition}`}
      sx={{
        //hidden on mobile - refer to navMenuMobile
        display: ["none", "flex"],
        //
        position: "fixed",
        height: ["80px", "95vh", "95vh"],
        width: ["100%", "30%", "22%"],
        backgroundColor: "primary",
        color: "white",
        mx: [0, 3, 3],
        my: ["0", "2.5vh", "2.5vh"],
        py: 2,
        px: 4,
        flexDirection: ["row", "column"],
        justifyContent: "space-between",
        zIndex: 19,
        "&.navbar-left": {
          left: 0,
        },
        "&.navbar-right": {
          right: 0,
        },
        "&.navbar-bottom": {
          bottom: 0,
          flexDirection: "row",
          height: "140px",
          width: "95%",
          alignItems: "center",
          "& h1": {
            wordBreak: "break-word",
          },
          "& .navHeadshot": {
            maxHeight: "130px",
            width: "100px",
          },
          "& h1 ::before": {},
          "& ul": {
            float: "none",
            display: "flex",
            "& li": {
              marginRight: 4,
            },
          },
        },
        "&.navbar-bottom.bottom": {
          position: "relative",
          mx: "auto",
        },
      }}
    >
      <h1
        sx={{
          fontSize: [3, 6],
          lineHeight: 1,
          marginLeft: 3,
          marginTop: 3,
          marginBottom: 2,
          display: "inline-block",
          position: "relative",
          maxWidth: ["100px", "200px"],
          "::before": {
            content: '""',
            display: "block",
            position: "absolute",
            width: ["4px", "8px"],
            height: ["45%", "75%"],
            left: "-16px",
            top: ["27.5%", "12.5%"], //half of what is left from height
            backgroundColor: "white",
          },
        }}
      >
        {data.site.frontmatter.name}
      </h1>
      <nav
        sx={{
          position: ["absolute", "relative"],
          top: ["25%", "auto"],
          width: ["100%", "auto"],
        }}
      >
        <ul
          sx={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            marginRight: 3,
            float: "right",
            textAlign: "right",
            "& li": {
              marginBottom: "0.4em",
            },
            "& a": {
              color: "white",
              textDecoration: "none",
              fontSize: 3,
              "&:hover, &.active": {
                textDecoration: "underline",
              },
            },
          }}
        >
          {/* <li>
            <button
              onClick={e => {
                setColorMode(colorMode === "default" ? "blue" : "default")
              }}
            >
              Toggle {colorMode === "default" ? "blue" : "red"}
            </button>
          </li> */}
          <li>
            <Link to="/" activeClassName="active">
              Home
            </Link>
          </li>
          <li>
            <Link to="/news" activeClassName="active" partiallyActive={true}>
              Latest News
            </Link>
          </li>
          <li>
            <Link to="/cv" activeClassName="active" partiallyActive={true}>
              CV
            </Link>
          </li>
          <li>
            <Link
              to="/portfolio"
              activeClassName="active"
              partiallyActive={true}
            >
              Gallery
            </Link>
          </li>
          <li>
            <Link
              to="/showreels"
              activeClassName="active"
              partiallyActive={true}
            >
              Showreels
            </Link>
          </li>
          <li>
            <Link
              to="/testimonials"
              activeClassName="active"
              partiallyActive={true}
            >
              Testimonials
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="active" partiallyActive={true}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      <div
        className="navHeadshot"
        sx={{
          px: 3,
          pb: 3,
          "& img": {
            border: "1px solid white",
          },
        }}
      >
        <Img
          fluid={data.site.frontmatter.headshot.childImageSharp.fluid}
          alt="Portia Booroff Headshot"
        />
      </div>
    </section>
  )
}

export default NavMenuDesktop

// export const query = graphql`
//   query {
//     site: markdownRemark (
//         frontmatter: {templateKey: {eq: "site-options"}}
//     ){
//       id
//         frontmatter {
//           name
//           headshot {
//               childImageSharp{
//                 fluid {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }

//           }

//     }

//   }
// `
