/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  const { site, markdownRemark } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        markdownRemark(frontmatter: { templateKey: { eq: "site-seo" } }) {
          frontmatter {
            seoTitle
            seoDesc
            seoTags
            socialImg {
              id
              childImageSharp {
                fixed {
                  src
                }
              }
            }
          }
        }
      }
    `
  )

  const metaDescription = description || markdownRemark.frontmatter.seoDesc
  const metaKeywords = markdownRemark.frontmatter.seoTags
  const socialImg =
    markdownRemark.frontmatter.socialImg.childImageSharp.fixed.src
  const siteUrl = site.siteMetadata.siteUrl
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${markdownRemark.frontmatter.seoTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: `${markdownRemark.frontmatter.seoTitle}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${siteUrl}/${socialImg}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${markdownRemark.frontmatter.seoTitle}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `referrer`,
          content: `origin`,
        },
      ].concat(meta)}
    ></Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
